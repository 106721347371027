<!--全景数据转换-->
<template>
  <div>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
    },
    data() {
      return {
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {}
    },
    computed: {
      pano() {
        return this.publicData.pano || {}
      },
    },
    watch: {
      pano(val) {
        this.updatePano(val)
      }
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
        }
      })
      this.updatePano()
    },
    created() {
    },
    destroyed() {
    },
    methods: {
      updatePano(pano) {
        var p = pano || this.pano
        var audios = {}, audiosConfig = this.publicData.audiosConfig || {}
        audios.defaultAudio = p.audio
        audiosConfig.defaultAudio = { ...audiosConfig.defaultAudio, autoPlay: p.audioAutoPlay, loop: p.audioLoop, priority: 100 }
        this.getFunc('changePublicData')({ guid: p.guid, audiosConfig, audios })
      }
    },
  }
</script>
<style scoped>
</style>
